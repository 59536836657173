import Vue from 'vue'
import VueMeta from 'vue-meta'
import PortalVue from 'portal-vue'
import {App, Link, plugin, createInertiaApp} from '@inertiajs/inertia-vue'
import {InertiaProgress} from '@inertiajs/progress/src'
import {VueMaskDirective} from 'v-mask'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import DatePicker from './lib/vue2-datepicker';
import VueMoment from 'vue-moment';
import moment from 'moment';
import 'moment/locale/ru';
import vClickOutside from 'v-click-outside'
import VueDraggableResizable from 'vue-draggable-resizable'
import store from './stores/store'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
// import * as Sentry from "@sentry/vue";

if (!String.prototype.padStart) {
    String.prototype.padStart = function padStart(targetLength, padString) {
        targetLength = targetLength >> 0; //floor if number or convert non-number to 0;
        padString = String(padString || ' ');
        if (this.length > targetLength) {
            return String(this);
        } else {
            targetLength = targetLength - this.length;
            if (targetLength > padString.length) {
                padString += padString.repeat(targetLength / padString.length); //append to original to ensure we are longer than needed
            }
            return padString.slice(0, targetLength) + String(this);
        }
    };
}

moment.locale('ru')
moment.updateLocale("ru", {
    invalidDate: ""
});

const el = document.getElementById('app')

createInertiaApp({
    initialPage: JSON.parse(el.dataset.page),
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({el, app, props, plugin}) {

        // Sentry.init({
        //     Vue,
        //     dsn: "https://4aa6d6e7ba97f31d3fdad32db9c9cafe@sentry.veeble.ru/2",
        //     integrations: [
        //         new Sentry.BrowserTracing(),
        //         new Sentry.Replay({
        //             maskAllText: false,
        //             blockAllMedia: false,
        //         })
        //     ],
        //     // Performance Monitoring
        //     tracesSampleRate: 1.0, //  Capture 100% of the transactions
        //     // Session Replay
        //     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        //     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        // });

        Vue.component('vue-draggable-resizable', VueDraggableResizable)

        Vue.use(vClickOutside)
        Vue.config.productionTip = false
        Vue.mixin({
            methods: {
                route: window.route,
                isRouteCurrent(...routes) {
                    return routes.some(route => {
                        return this.route().current(route)
                    });
                }
            }
        })
        Vue.mixin({methods: {route: window.route}})
        // Vue.use(plugin)
        Vue.use(PortalVue)
        Vue.use(VueMeta)
        Vue.use(VueMoment, {
            moment
        });
        Vue.directive('mask', VueMaskDirective);


        Vue.component('inertia-link', Link);
        Vue.component('v-select', vSelect)
        Vue.component('v-date-picker', DatePicker)

        InertiaProgress.init()

        Vue.prototype.$fn = {}

        Vue.use(plugin);

        // global components
        // @ts-ignore
        Vue.component('Link', Link);

        new Vue({
            metaInfo: {
                titleTemplate: title => (title || 'Asu'),
            },
            store,
            render: h => h(app, props)
        }).$mount(el);
    }
});

window.Echo = new Echo({
    broadcaster: 'pusher',
    client: new Pusher(import.meta.env.VITE_PUSHER_APP_KEY, {
        enableStats: false,
        forceTLS: true,
        wsHost: import.meta.env.VITE_PUSHER_HOST,
        enabledTransports: ['ws', 'wss'],
        channelAuthorization: {
            endpoint: '/broadcasting/auth',
        },
    }),
})
